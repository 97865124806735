<script setup lang="ts">
const { isDashboardSearchModalOpen } = useUIState()
const { metaSymbol } = useShortcuts()
const router = useRouter()
const { clear, user } = useUserSession()

const items = computed(() => [
	[{
		slot: 'account',
		label: '',
		disabled: true
	}], [{
		label: 'Perfil',
		icon: 'i-heroicons-user',
		to: '/perfil'
	}], [{
		label: 'Configurações',
		icon: 'i-heroicons-cog-8-tooth',
		to: '/settings/members'
	}, {
		label: 'Comandos',
		icon: 'i-heroicons-command-line',
		shortcuts: [metaSymbol.value, 'K'],
		click: () => {
			isDashboardSearchModalOpen.value = true
		}
	}], [{
		label: 'Sair',
		icon: 'i-heroicons-arrow-left-on-rectangle',
		click: async () => {
			await clear()
			await router.push('/login')
		}
	}]
])
</script>

<template>
	<UDropdown
		mode="hover"
		:items="items"
		:ui="{ width: 'w-full', item: { disabled: 'cursor-text select-text' } }"
		:popper="{ strategy: 'absolute', placement: 'top' }"
		class="w-full"
	>
		<template #default="{ open }">
			<UButton
				color="gray"
				variant="ghost"
				class="w-full"
				:label="user?.nome"
				:class="[open && 'bg-gray-50 dark:bg-gray-800']"
			>
				<template #default>
					{{ user?.nome }}
					<Icon
						name="material-symbols:verified-rounded"
						class="w-5 h-5 text-green-500"
					/>
				</template>
				<template #leading>
					<UAvatar
						:src="user?.linkFotoPerfil"
						size="2xs"
					/>
				</template>

				<template #trailing>
					<UIcon
						name="i-heroicons-ellipsis-vertical"
						class="w-5 h-5 ml-auto"
					/>
				</template>
			</UButton>
		</template>

		<template #account>
			<div class="text-left">
				<p>
					Autenticado como
				</p>
				<p class="truncate font-medium text-gray-900 dark:text-white">
					{{ user?.login }}
				</p>
			</div>
		</template>
	</UDropdown>
</template>
